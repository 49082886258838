import React, {useEffect, useState} from 'react';
import T from "../../../../../components/T";
import {motion} from "framer-motion";
import Division from "../../../../../http/Division";
import {Button, Input, notification, Progress, Select, Switch, Tag} from "antd";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faArrowRightLong, faCheck} from "@fortawesome/free-solid-svg-icons";
import GoogleImage from "./GoogleImage";
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Canada from "../../../../../http/Canada";
import {  } from 'antd';

const { Option } = Select;

const CanadaValidate = ({approvedType = false}) => {

    const [loaded, setLoaded] = useState(false);
    const [batch, setBatch] = useState(null);
    const [batchIndex, setBatchIndex] = useState(0);
    const [item, setItem] = useState(0);
    const [nextItem, setNextItem] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [contexts, setContexts] = useState(false);
    const [selectedContext, setSelectedContext] = useState(0);
    const [sorting, setSorting] = useState(false);
    const [onlyNotApproved, setOnlyNotApproved] = useState(false);
    const [locations, setLocations] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedState, setSelectedState] = useState(0);
    const [selectedCity, setSelectedCity] = useState(0);
    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedNationality, setSelectedNationality] = useState(0);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [distance, setDistance] = useState('');
    const [rates, setRates] = useState('');

    const handleChange = (selected) => {
        setSelectedCategories(selected);
    };

    useEffect(() => {
        reload();
    }, [selectedContext, onlyNotApproved, selectedCountry, selectedState, selectedCity, selectedCategories, selectedNationality]);

    const reload = () => {
        setBatch(null);
        setBatchIndex(0)
        setItem(null);

        if (approvedType) {
            Canada.ApprovedBrandsIndex(approvedType, {sort: {context_id: selectedContext}, nationality_id: selectedNationality, rates, lat,lng,distance, search: search, country_id: selectedCountry, state_id: selectedState, city_id: selectedCity, only_not_approved: false, categories: selectedCategories}, (response) => {
                const data = response.data.data.batch;
                setContexts(response.data.data.contexts)
                setLocations(response.data.data.locations);
                setCategories(response.data.data.categories);
                setNationalities(response.data.data.nationalities);
                setBatch(data);
                const index = 0;
                setBatchIndex(index);
                setItem(data[index]);
                if (index < data.length - 1)
                    setNextItem(data[index + 1]);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            Canada.ValidateIndex({sort: {context_id: selectedContext}, nationality_id: selectedNationality, rates, lat,lng,distance, search: search, country_id: selectedCountry, state_id: selectedState, city_id: selectedCity, only_not_approved: onlyNotApproved, categories: selectedCategories}, (response) => {
                const data = response.data.data.batch;
                setContexts(response.data.data.contexts)
                setLocations(response.data.data.locations);
                setCategories(response.data.data.categories);
                setNationalities(response.data.data.nationalities);
                setBatch(data);
                let index = data.findIndex(item => item.approve_status === 'not approved');
                if (index >= 0) {
                    setItem(data[index]);
                    if (index < data.length - 1)
                        setNextItem(data[index + 1]);
                    setBatchIndex(index);
                } else {
                    setItem(data[data.length - 1]);
                    setBatchIndex(data.length - 1);
                    setCompleted(true)
                }
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }

    }


    useEffect(() => {
        if (batch) {
            setItem(batch[batchIndex]);
            if (batchIndex < batch.length - 1)
                setNextItem(batch[batchIndex + 1]);
        }
    }, [batchIndex]);

    const moveRight = () => {
        setBatchIndex((prevIndex) => {
            if (prevIndex < batch.length - 1) {
                return prevIndex + 1;
            }
            return prevIndex;
        });
    };

    const moveLeft = () => {
        setBatchIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }
            return prevIndex;
        });
    };
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.keyCode === 37) {
                moveLeft();
            } else if (event.keyCode === 39) {
                moveRight();
            } else if (event.keyCode === 13) {
                statusChange('approved');
            } else if (event.keyCode === 46) { // 46 is the Delete key code
                statusChange('rejected');
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [batch, item]);

    const statusChange = (value) => {
        setItem({...item, approve_status: value});
        Canada.UpdateStatus(item.id, value, (response) => {
            notification['success']({
                message: response.data.message
            });

            const updatedBatch = batch.map(it => {
                if (it.id === item.id) {
                    return {...it, approve_status: value};
                }
                return it;
            });


            setBatch(updatedBatch);


        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
        if (!approvedType)
            moveRight();
    }

    const contextChange = (context_id) => {
        setSelectedContext(context_id);
    }

    const nationalityChange = (nationality_id) => {
        setSelectedNationality(nationality_id);
    }

    const onOnlyNotApprovedChange = (context_id) => {
        setOnlyNotApproved(!onlyNotApproved);
    }

    const countryChange = (country_id) => {
        setSelectedCountry(country_id);
        setSelectedState(0);
        setSelectedCity(0);
    }

    const stateChange = (state_id) => {
        setSelectedState(state_id);
        setSelectedCity(0);
    }

    const cityChange = (city_id) => {
        setSelectedCity(city_id);
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const onLatChange = (e) => {
        setLat(e.target.value);
    }

    const onLngChange = (e) => {
        setLng(e.target.value);
    }

    const onDistanceChange = (e) => {
        setDistance(e.target.value);
    }

    const onRatesChange = (e) => {
        setRates(e.target.value);
    }

    const onSearch = () => {
        reload();
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className={"flex flex-col items-start"}>
                    <div className="text-3xl patty font-bold text-black pl-1 flex flex-row justify-between mb-2 flex-1">
                        {approvedType ?
                            <T upper>{approvedType=='khaz'?'age residim':''} {approvedType=='standard'?'mohem':''} {approvedType=='fancy'?'VAJEB':''} Brands</T>
                            :
                            <T upper>Validate Canada Brands</T>
                        }
                    </div>
                    <div className={"pr-2 w-full"}>
                        {contexts &&
                            <div className={"flex flex-col items-center md:items-end w-full"}>
                                <div className={"flex flex-col md:flex-row w-full "}>
                                    <div className={"flex flex-col md:flex-row items-center md:items-center py-1 bg-gray-200 border border-gray-300 rounded-md md:rounded-full pr-4 pl-5 mr-3 w-full md:w-auto"}>
                                        <div className={"flex flex-row items-center"}>
                                            <div className={"font-bold mr-2"}>Country:</div>
                                            <Select
                                                key={'country'}
                                                className={"w-48"}
                                                showSearch={true}
                                                onChange={countryChange}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                defaultValue={0}
                                                options={[{value: 0, label: "All Countries"}, ...locations.map((country) => {
                                                    return ({value: country["country_id"], label: country["country"]})
                                                })]}
                                            />
                                        </div>

                                        <div className={"flex flex-row items-center ml-4"}>
                                            <div className={"font-bold mr-2"}>State:</div>
                                            <Select
                                                key={'state'}
                                                className={"w-48"}
                                                showSearch={true}
                                                onChange={stateChange}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                defaultValue={0}
                                                value={selectedState}
                                                disabled={selectedCountry == 0}
                                                options={selectedCountry > 0 ?
                                                    [{value: 0, label: "All States"}, ...locations.filter(country => country.country_id == selectedCountry)[0].states.map((state) => {
                                                        return ({value: state["state_id"], label: state["state"]})
                                                    })] : [{value: 0, label: "All States"}]
                                                }
                                            />
                                        </div>

                                        <div className={"flex flex-row items-center ml-4"}>
                                            <div className={"font-bold mr-2"}>City:</div>
                                            <Select
                                                key={'city'}
                                                className={"w-48"}
                                                showSearch={true}
                                                onChange={cityChange}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                defaultValue={0}
                                                value={selectedCity}
                                                disabled={selectedState == 0}
                                                options={selectedCountry > 0 && selectedState > 0 ?
                                                    [{value: 0, label: "All Cities"}, ...locations.filter(country => country.country_id == selectedCountry)[0].states.filter(state => state.state_id == selectedState)[0].cities.map((city) => {
                                                        return ({value: city["city_id"], label: city["city"]})
                                                    })] : [{value: 0, label: "All Cities"}]
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className={"flex flex-row items-center py-1 bg-gray-200 border border-gray-300 rounded-md md:rounded-full pr-3 pl-3 w-full md:w-auto mt-1"}>
                                        <div className={"uppercase font-bold mr-2"}>Sort:</div>
                                        <Select
                                            key={'context'}
                                            className={"w-full md:w-[450px]"}
                                            showSearch={true}
                                            onChange={contextChange}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={sorting}
                                            defaultValue={0}
                                            value={selectedContext}
                                            options={
                                                [
                                                    {value: 0, label: 'Total Reviews / Age'},
                                                    {value: -1, label: 'Whites Percentage'},
                                                    {value: -2, label: 'Whites Occurrence'},
                                                    ...contexts.map((context) => {
                                                    return ({value: context["id"], label: context["category_full_name"]})
                                                })]
                                            }
                                        />
                                    </div>

                                    <div className={"flex flex-row items-center py-1 bg-gray-200 border border-gray-300 rounded-md md:rounded-full pr-3 pl-3 w-full md:w-auto mt-1 ml-3"}>
                                        <div className={"uppercase font-bold mr-2"}>Nationality:</div>
                                        <Select
                                            key={'nationality'}
                                            className={"w-full md:w-[250px]"}
                                            showSearch={true}
                                            onChange={nationalityChange}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={sorting}
                                            defaultValue={0}
                                            value={selectedNationality}
                                            options={
                                                [
                                                    {value: 0, label: 'N/A'},
                                                    ...nationalities.map((nationality) => {
                                                        return ({value: nationality["id"], label: nationality["name"]})
                                                    })]
                                            }
                                        />
                                    </div>

                                </div>
                                <div className={"flex flex-row items-center py-1 bg-gray-200 border border-gray-300 rounded-md md:rounded-full pr-3 pl-3 w-full md:w-auto mt-1"}>
                                    <div className={"flex flex-row items-center ml-4 my-2"}>

                                        <Input
                                            placeholder="rates"
                                            style={{
                                                width: 120,
                                                marginRight: 10
                                            }}
                                            onChange={onRatesChange}
                                            allowClear
                                        />

                                        <Input
                                            placeholder="lat"
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={onLatChange}
                                            allowClear
                                        />

                                        <Input
                                            placeholder="lng"
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={onLngChange}
                                            allowClear
                                        />

                                        <Input
                                            placeholder="distance"
                                            style={{
                                                width: 100,
                                                marginRight: 10
                                            }}
                                            onChange={onDistanceChange}
                                            allowClear
                                        />

                                        <Input
                                            placeholder="input search text"
                                            style={{
                                                width: 230,
                                            }}
                                            onChange={onSearchChange}
                                            allowClear
                                        />
                                        <Button onClick={onSearch}>Search</Button>
                                    </div>
                                </div>
                                <div className={"flex flex-row items-center"}>
                                    {!approvedType &&
                                        <div className={"flex flex-row items-center py-1 bg-gray-200 border border-gray-300 rounded-md md:rounded-full pr-1 pl-3 my-1 ml-3"}>
                                            <div className={"uppercase mr-2 font-bold"}>Not Approved Only</div>
                                            <Switch checked={onlyNotApproved} size={"default"} onChange={onOnlyNotApprovedChange}/>
                                        </div>
                                    }
                                </div>
                                <div className={"w-full"}>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        className={"w-full"}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={handleChange}

                                    >
                                        {categories.map(category => (
                                            <Option key={category.id} value={category.name}>
                                                {category.name} ({category.cnt})
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-sm flex-1 border border-gray-400 shadow-xl bg-white p-3 flex flex-col items-center justify-center py-3 mt-2">
                        {completed && <div>All brands has been validated!</div>}
                        <SkeletonWrapper loading={!batch} skeleton={GenericSkeleton}>
                            {(batch && batch.length == 0) &&
                                <div className={"uppercase text-gray-400"}>The list is empty.</div>
                            }
                            {batch && item &&
                                <div className={"flex flex-col w-full"}>
                                    <div className={"flex flex-row mb-1"}>
                                        <Progress
                                            type="line"
                                            strokeColor={{
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            }}
                                            percent={Math.round((batchIndex + 1) / batch.length * 100, 0)}
                                        />
                                        <div className={"mr-2 px-2 py-0 bg-yellow-100 flex flex-row whitespace-nowrap"}>
                                            {batchIndex + 1} out of {batch.length}
                                        </div>
                                    </div>

                                    <div className={"mt-2 mb-3 w-full justify-center flex flex-col md:flex-row bg-gray-300 p-1 border border-gray-400 shadow-lg rounded-md md:rounded-full"}>
                                        <div className={"flex flex-row text-2xl items-center justify-center"}>
                                            {(batchIndex > 0) &&
                                                <div className={"px-2 cursor-pointer"} onClick={moveLeft}>
                                                    <FontAwesomeIcon icon={faArrowLeftLong} aria-hidden="true" className={"mr-1"}/>
                                                </div>
                                            }
                                            <div className={"mx-2 flex flex-col md:flex-row items-center justify-center border border-gray-400 rounded-lg uppercase bg-white text-base overflow-hidden"}>
                                                <div
                                                    className={`cursor-pointer w-full border-r border-gray-400 px-2 py-1 flex flex-row items-center ${item.approve_status == "not approved" ? 'bg-gray-800 text-white font-bold' : ''}`}
                                                    onClick={() => statusChange('not approved')}
                                                >
                                                    {item.approve_status == "not approved" &&
                                                        <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl " aria-hidden="true"/>
                                                    }
                                                    not approved
                                                </div>
                                                <div
                                                    className={`cursor-pointer md:border-r border-gray-400 px-2 py-1 flex flex-row items-center whitespace-nowrap ${item.approve_status == "khaz" ? 'bg-red-700 text-white font-bold' : ''}`}
                                                    onClick={() => statusChange('khaz')}
                                                >
                                                    {item.approve_status == "khaz" &&
                                                        <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl " aria-hidden="true"/>
                                                    }
                                                    age residim
                                                </div>
                                                <div
                                                    className={`cursor-pointer md:border-r md:border-gray-400 px-2 py-1 flex flex-row items-center ${item.approve_status == "standard" ? 'bg-green-700 text-white font-bold' : ''}`}
                                                    onClick={() => statusChange('standard')}
                                                >
                                                    {item.approve_status == "standard" &&
                                                        <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl " aria-hidden="true"/>
                                                    }
                                                    mohem
                                                </div>
                                                <div
                                                    className={`cursor-pointer px-2 py-1 flex flex-row items-center whitespace-nowrap ${item.approve_status == "fancy" ? 'bg-pink-700 text-white font-bold' : ''}`}
                                                    onClick={() => statusChange('fancy')}
                                                >
                                                    {item.approve_status == "fancy" &&
                                                        <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl " aria-hidden="true"/>
                                                    }
                                                    vajeb
                                                </div>
                                            </div>
                                            {(batchIndex < (batch.length - 1)) &&
                                                <div className={"px-2 cursor-pointer"} onClick={moveRight}>
                                                    <FontAwesomeIcon icon={faArrowRightLong} aria-hidden="true" className={"mr-1"}/>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                    <div className={"flex flex-col md:flex-row w-full"}>
                                        <div className={"flex flex-col flex-1"}>
                                            <div className={"flex flex-wrap m-2 md:m-0 md:flex-row text-2xl font-bold justify-start w-full items-center uppercase bold"}>
                                                <div className={"p-1"}><a href={`https://www.google.com/maps/place/?q=place_id:${item.place_id}`} className={"text-black"} target={"_blank"}>{item.name}</a></div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-purple-200"}>{item.rating}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-blue-200"}>Ratings: {item.total_ratings}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-blue-200"}>Reviews: {item.total_reviews}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-yellow-200"}>Age: {item.age} Years</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-gray-200"}>Persian: %{item.persian_percent.toFixed(1)}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-gray-200"}>Persian Reviewers: %{item.reviewer_persian_percent.toFixed(1)}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-gray-200"}>Whites Percent: %{item.whites_percent.toFixed(1)}</div>
                                                <div className={"m-1 md:ml-2 px-2 text-base bg-gray-200"}>Whites Occurrence: {(item.whites_percent*item.total_ratings/100).toFixed(0)}</div>
                                                {item.top_hour &&
                                                    <div className={"ml-2 px-2 text-base bg-pink-300"}>Top Hour: {item.top_hour}</div>
                                                }
                                            </div>
                                            <div className={"flex flex-row text-sm font-bold justify-start w-full items-start p-1"}>
                                                <div className={"bg-gray-100 mr-1 px-2"}>{item.country}</div>
                                                <div className={"bg-gray-100 mr-1 px-2"}>{item.city}</div>
                                                <div className={"bg-gray-100 mr-1 px-2"}>{item.address}</div>
                                            </div>

                                            {item.highlights.length > 0 &&
                                                <div className={"flex flex-wrap pt-2"}>
                                                    {item.highlights.map(name => {
                                                        return (
                                                            <div className={"bg-yellow-200 m-1 px-2 uppercase font-bold"}>{name}</div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <div className={"px-3 bg-white rounded-lg mr-2 border border-gray-200 shadow-lg mb-1"}>
                                            {item.popular_hours.length > 0 &&
                                                <div className={"flex flex-row items-center"}>
                                                    <BarChart width={300} height={180} data={item.popular_hours.map((h, index) => {
                                                        return {name: 6 + index, Popularity: h}
                                                    })}>
                                                        <Bar dataKey="Popularity" fill="#8884d8"/>
                                                        <XAxis dataKey="name" style={{fontSize: "12px"}}/>
                                                        <Legend/>
                                                    </BarChart>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={"flex flex-col pt-2"}>
                                        <div className={"p-1 font-bold text-lg"}><a href={`https://www.google.com/maps/place/?q=place_id:${item.place_id}`} className={"text-black"} target={"_blank"}>{item.name}</a></div>
                                        <div className={"flex flex-wrap"}>
                                            {item.images.map(url => {
                                                return (
                                                    <GoogleImage url={url} key={url}/>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    {item.uber_foods.length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center bg-pink-800 mt-2 shadow-lg"}>
                                            <div className={"text-lg patty font-bold px-2 text-white"}>Uber Top Foods</div>
                                            {item.uber_foods.map((food) => {
                                                return (
                                                    <div className={"bg-gray-100 m-1 px-2 "}>
                                                        {food.name} : <b className={""}>%{food.rate} ({food.votes})</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {Object.keys(item.top_nationalities).length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center bg-black mt-2 shadow-lg"}>
                                            <div className={"text-lg patty font-bold px-2 text-white"}>Top Nationalities</div>
                                            {Object.keys(item.top_nationalities).map((key) => {
                                                return (
                                                    <div className={"bg-gray-100 m-1 px-2 "}>
                                                        {key} : <b className={""}>%{item.top_nationalities[key].toFixed(2)}</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {Object.keys(item.country_cuisine).length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center bg-gray-800 mt-2 shadow-lg"}>
                                            <div className={"text-lg patty font-bold px-2 text-white"}>Cuisine Categories:</div>
                                            {Object.keys(item.country_cuisine).map((key) => {
                                                return (
                                                    <div className={"bg-gray-100 m-1 px-2 "}>
                                                        {key} : <b className={""}>%{item.country_cuisine[key].toFixed(2)}</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {Object.keys(item.country_halal).length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center bg-gray-700 mt-2 shadow-lg"}>
                                            <div className={"text-lg patty font-bold px-2 text-white"}>Halal:</div>
                                            {Object.keys(item.country_halal).map((key) => {
                                                return (
                                                    <div className={"bg-gray-100 m-1 px-2 "}>
                                                        {key} : <b className={""}>%{item.country_halal[key].toFixed(2)}</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {Object.keys(item.country_income).length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center bg-gray-600 mt-2 shadow-lg"}>
                                            <div className={"text-lg patty font-bold px-2 text-white"}>Income Level:</div>
                                            {Object.keys(item.country_income).map((key) => {
                                                return (
                                                    <div className={"bg-gray-100 m-1 px-2 "}>
                                                        {key} : <b className={""}>%{item.country_income[key].toFixed(2)}</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }


                                    {Object.keys(item.contexts_rankings).length > 0 &&
                                        <div className={"flex flex-wrap text-sm font-bold justify-start w-full items-start p-1 pt-2 items-center"}>
                                            <div className={"text-lg patty font-bold"}>Top Ranking Contexts</div>
                                            {Object.keys(item.contexts_rankings).map((key) => {
                                                return (
                                                    <div className={"bg-green-100 m-1 px-2 "}>
                                                        {key} : <b className={"text-xl"}>{item.contexts_rankings[key]}</b>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }


                                    {nextItem &&
                                        <div className={"flex flex-wrap pt-2 hidden"}>
                                            {nextItem.images.map(url => {
                                                return (
                                                    <GoogleImage url={url} key={url}/>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            }
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default CanadaValidate;
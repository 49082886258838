import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Button, InputNumber, notification, Tag, Popconfirm } from 'antd';
import OrderService from '../../../../../http/OrderService';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrderPage = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [startOfDayValues, setStartOfDayValues] = useState([]);
    const [branch, setBranch] = useState(null);
    const [forecasts, setForecasts] = useState([]);
    const [adjustedForecasts, setAdjustedForecasts] = useState({});
    const [orderQuantities, setOrderQuantities] = useState({});
    const [adjustmentCoefficient, setAdjustmentCoefficient] = useState(null);
    const [selectedDayIndices, setSelectedDayIndices] = useState({});
    const [loadingOrder, setLoadingOrder] = useState({});
    const [loadingCancel, setLoadingCancel] = useState({});

    useEffect(() => {
        if (branchId) {
            fetchOrderPageData(branchId);
        }
    }, [branchId]);

    const fetchOrderPageData = (branchId) => {
        setLoading(true);
        OrderService.getOrderPageData(branchId,
            response => {
                const data = response.data.data;
                setStartOfDayValues(data.start_of_day_values);
                setForecasts(data.forecasts);
                setAdjustmentCoefficient(data.adjustment_coefficient);
                setBranch(data.branch);
                setLoading(false);
            },
            error => {
                notification.error({
                    message: 'Error',
                    description: error.response?.data.message?error.response?.data.message:'Failed to load order page data',
                });
                setLoading(false);
            });
    };

    const handleQuantityChange = (branchInventoryGroupId, value) => {
        setOrderQuantities({
            ...orderQuantities,
            [branchInventoryGroupId]: value,
        });
    };

    const handleForecastChange = (forecastId, value) => {
        setAdjustedForecasts({
            ...adjustedForecasts,
            [forecastId]: value,
        });
    };

    const handleOrder = (branchInventoryGroupId) => {
        const quantity = orderQuantities[branchInventoryGroupId];
        if (!quantity) {
            notification.error({
                message: 'Error',
                description: 'Please enter a quantity',
            });
            return;
        }

        setLoadingOrder({
            ...loadingOrder,
            [branchInventoryGroupId]: true,
        });

        OrderService.placeOrder({
                branch_inventory_group_id: branchInventoryGroupId,
                quantity,
                order_date: new Date().toISOString().split('T')[0],
            },
            response => {
                const data = response.data.data;
                setStartOfDayValues(data.start_of_day_values);
                setForecasts(data.forecasts);
                setLoadingOrder({
                    ...loadingOrder,
                    [branchInventoryGroupId]: false,
                });
                notification.success({
                    message: 'Success',
                    description: "Order Placed successfully.",
                });
            },
            error => {
                setLoadingOrder({
                    ...loadingOrder,
                    [branchInventoryGroupId]: false,
                });
                notification.error({
                    message: 'Error',
                    description: 'Failed to place order',
                });
            });
    };

    const handleCancelOrder = (orderId, branchInventoryGroupId) => {
        setLoadingCancel({
            ...loadingCancel,
            [orderId]: true,
        });

        OrderService.cancelOrder(orderId,
            response => {
                const data = response.data.data;
                setStartOfDayValues(data.start_of_day_values);
                setForecasts(data.forecasts);
                setLoadingCancel({
                    ...loadingCancel,
                    [orderId]: false,
                });
                notification.success({
                    message: 'Success',
                    description: "Order Cancelled successfully.",
                });
            },
            error => {
                setLoadingCancel({
                    ...loadingCancel,
                    [orderId]: false,
                });
                notification.error({
                    message: 'Error',
                    description: 'Failed to cancel order',
                });
            });
    };

    const calculateDailyValues = (inventory, forecast) => {
        const adjustedForecast = adjustedForecasts[forecast.id] || forecast.predicted_burgers;
        const usedQuantity = inventory.coef * adjustedForecast * inventory.unit_size * adjustmentCoefficient;
        const wasteQuantity = usedQuantity * (inventory.waste_percentage / 100);
        return { usedQuantity, wasteQuantity, totalQuantity: usedQuantity + wasteQuantity };
    };

    const calculateNeededQuantity = (startOfDayQuantity, dailyUsages) => {
        let remainingQuantity = startOfDayQuantity;
        const rn = dailyUsages.map(dailyUsage => {
            remainingQuantity -= dailyUsage.totalQuantity;
            const neededQuantity = remainingQuantity < 0 ? Math.abs(remainingQuantity) : 0;
            return { remainingQuantity, neededQuantity };
        });
        return rn;
    };

    const handleInfoDivClick = (record, neededQuantity, index) => {
        const roundedNeededQuantity = Math.ceil(neededQuantity / record.order_batch_size) * record.order_batch_size;
        setOrderQuantities({
            ...orderQuantities,
            [record.branch_inventory_group_id]: roundedNeededQuantity
        });
        setSelectedDayIndices({
            ...selectedDayIndices,
            [record.branch_inventory_group_id]: index
        });
    };

    const statusColorMap = {
        pending: '#ececec',     // Light Blue
        seen: '#e4f9ff',        // Thistle
        confirmed: '#90EE90',   // Light Green
        preparing: '#FFDAB9',   // Peach Puff
        shipped: '#fee0ff',     // Light Cyan
        received: '#D3D3D3'     // Light Gray
    };

    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'branch_inventory_group_name',
            render: (text, record) => (
                <div className={"font-bold patty text-xl"}>{text}</div>
            ),
        },
        {
            title: 'Start of Day',
            dataIndex: 'start_day_inventory',
            key: 'start_day_inventory',
            render: (text, record) => (
                <div className={"flex flex-col"}>
                    <div className="flex flex-row items-center">
                        <span className="mr-2"><b>{text.toFixed(0)}</b> <span className={"capitalize-first text-xs"}>{record.unit_type}</span></span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Info',
            dataIndex: 'information',
            key: 'information',
            render: (text, record) => (
                <div className={"whitespace-nowrap"}>
                    <div className={"text-xs"}>
                        Unit Type: <b>{record.branch_inventory_group.unit_type}</b>
                    </div>
                    <div className={"border-t border-gray-100 mt-1 text-xs pt-1"}>
                        Order Batch Size: <b>{record.order_batch_size}</b>
                    </div>
                    <div className={"border-t border-gray-100 mt-1 text-xs pt-1"}>
                        Vendor: <b>{record.vendor_name}</b>
                    </div>
                </div>
            ),
        },
        {
            title: 'Forecasted Quantity Needed',
            key: 'forecasted_quantity',
            render: (text, record) => {
                const dailyUsages = record.branch_inventory_group.branch_inventories.map(inventory =>
                    forecasts.map(forecast => calculateDailyValues(inventory, forecast))
                );

                const aggregatedUsages = forecasts.map((_, index) => {
                    const aggregated = dailyUsages.reduce((acc, usage) => {
                        acc.usedQuantity += usage[index].usedQuantity;
                        acc.wasteQuantity += usage[index].wasteQuantity;
                        acc.totalQuantity += usage[index].totalQuantity;
                        return acc;
                    }, { usedQuantity: 0, wasteQuantity: 0, totalQuantity: 0 });
                    return aggregated;
                });

                const neededQuantities = calculateNeededQuantity(record.start_day_inventory, aggregatedUsages);

                return (
                    <div className={"flex flex-wrap "}>
                        {forecasts.map((forecast, index) => {
                            const { usedQuantity, wasteQuantity, totalQuantity } = aggregatedUsages[index];
                            const { remainingQuantity, neededQuantity } = neededQuantities[index];
                            const isSelected = selectedDayIndices[record.branch_inventory_group_id] !== undefined && index <= selectedDayIndices[record.branch_inventory_group_id];
                            return (
                                <div
                                    className={`text-black border-gray-300 border m-1 pt-1 text-center rounded-sm w-[140px] flex flex-col justify-between noselect shadow-md ${isSelected ? 'bg-blue-100' : 'bg-gray-50 '} cursor-pointer`}
                                    key={index}
                                    onClick={() => handleInfoDivClick(record, neededQuantity, index)}
                                >
                                    <div>
                                        <span className={"text-black text-sm font-bold pr-1"}>{forecast.forecast_date}</span>
                                        <div className="text-xs text-gray-600 mt-1">
                                            Usage: <b>{usedQuantity.toFixed(0)}</b>
                                        </div>
                                        <div className="text-xs text-gray-600 mt-1">
                                            Waste: <b>{wasteQuantity.toFixed(0)}</b>
                                        </div>
                                        <div className="text-xs text-gray-600 mt-1">
                                            Total: <b>{totalQuantity.toFixed(0)}</b>
                                        </div>
                                        <div className="text-xs text-gray-600 border-t pt-2 mt-1">
                                            Remaining: <b>{remainingQuantity.toFixed(0)}</b>
                                        </div>
                                    </div>
                                    <div className={`mt-2 ${neededQuantity > 0 ? 'bg-red-800' : 'bg-green-800'} text-white border-t`} style={{ height: "35px" }}>
                                        <div className={"py-2"}>
                                            {neededQuantity > 0 ?
                                                <div className="text-xs">
                                                    Required: <b>{neededQuantity.toFixed(0)}{record.unit_type}</b>
                                                </div>
                                                :
                                                <FontAwesomeIcon icon={faCheck} className="text-lg " aria-hidden="true" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            title: 'Active Orders',
            key: 'active_orders',
            render: (text, record) => (
                <div className={"flex flex-col items-start justify-start"}>
                    {record.pending_orders?.map(order => (
                        <div key={order.id} className="m-1 px-3 py-2 border rounded flex flex-col items-center " style={{ backgroundColor: statusColorMap[order.status] }}>
                            <div className={"font-bold pb-1"}>{order.ordered_amount}{record.unit_type}</div>
                            <div style={{ fontSize: "10px" }} className={"flex flex-row items-center text-center"}>{order.ordered_at}</div>
                            <hr />
                            <div className={"uppercase"}>{order.status}</div>
                            {order.status === 'pending' && (
                                <Popconfirm
                                    title="Are you sure you want to cancel this order?"
                                    onConfirm={() => handleCancelOrder(order.id, record.branch_inventory_group_id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button className={"mt-1"} size="small" danger loading={loadingCancel[order.id]}>
                                        Cancel
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Order Quantity',
            key: 'order_quantity',
            render: (text, record) => (
                <div className={"flex flex-col items-start justify-start"}>
                    <InputNumber
                        min={0}
                        value={orderQuantities[record.branch_inventory_group_id]}
                        addonAfter={record.unit_type}
                        onChange={(value) => handleQuantityChange(record.branch_inventory_group_id, value)}
                        style={{ width: "130px" }}
                    />
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button onClick={() => handleOrder(record.branch_inventory_group_id)} loading={loadingOrder[record.branch_inventory_group_id]}>Order</Button>
            ),
        },
    ];

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <>
                <div className="patty text-2xl font-bold mb-4">Order Page - {branch && <span>{branch.name}</span>}</div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <div className={"bg-blue-50 px-2 py-2 border-gray-300 mb-2 border rounded-md hidden"}>
                        <div className={"patty text-xl"}>Burger Sales - Future Forecasts</div>
                        <div className="flex flex-wrap">
                            {forecasts.map((forecast) => {
                                const adjustedValue = adjustedForecasts[forecast.id] || forecast.predicted_burgers;
                                const adjustedValueWithCoefficient = adjustedValue * adjustmentCoefficient;
                                return (
                                    <div key={forecast.id} className="m-1 bg-gray-100 px-1 py-1 pl-3 border border-gray-300 shadow-lg">
                                        <span className="font-bold mr-2 w-32 text-right">{forecast.forecast_date}:</span>
                                        <InputNumber
                                            min={0}
                                            value={adjustedValue}
                                            onChange={(value) => handleForecastChange(forecast.id, value)}
                                            style={{ width: "70px" }}
                                        />
                                        <div className="text-xs ml-2 text-right mt-1 pr-1">
                                            Adjusted: <b>{adjustedValueWithCoefficient.toFixed(0)}</b>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={"mt-2"}>
                            <span className={"font-bold ml-2 mr-2 uppercase"}>Adjustment Coefficient:</span>
                            <InputNumber
                                min={1}
                                step={0.01}
                                value={adjustmentCoefficient}
                                onChange={setAdjustmentCoefficient}
                                style={{ width: "70px" }}
                            />
                        </div>
                    </div>
                    {startOfDayValues.length > 0 && forecasts.length > 0 && (
                        <div className={"pb-2"}>
                            <Table
                                columns={columns}
                                dataSource={startOfDayValues}
                                pagination={false}
                                rowKey="id"
                            />
                        </div>
                    )}
                </SkeletonWrapper>
            </>
        </DefaultContainer>
    );
};

export default OrderPage;

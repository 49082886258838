import React, {useState, useEffect} from 'react';
import {Pagination, Select, notification} from 'antd';
import DefaultContainer from '../../../../../components/DefaultContainer';
import BranchInventoryGroup from "../../../../../http/BranchInventoryGroup";
import {useParams} from "react-router-dom";
import Table from 'ant-responsive-table';
import Styled from "styled-components";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../../../components/skeletons/MenuSkeleton";

const ContainerWrapper = Styled.div`
    .ant-card-body div {
        white-space: nowrap;
        width: auto !important;
    }
`;

const BranchDailyRecords = () => {
    const {branchId} = useParams();
    const [records, setRecords] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(3);
    const [totalDays, setTotalDays] = useState(0);

    useEffect(() => {
        fetchDailyRecords();
    }, [page, perPage]);

    const fetchDailyRecords = () => {
        setLoading(true);
        BranchInventoryGroup.GetDailyRecords(
            branchId,
            page,
            perPage,
            (response) => {
                const formattedRecords = response.data.data.records;
                // Format data
                Object.keys(formattedRecords).forEach(date => {
                    formattedRecords[date] = formattedRecords[date].map(record => ({
                        ...record,
                        group_name: record.branch_inventory_group.name,
                        waste_percentage: record.used_amount ? ((record.wasted_amount / record.used_amount) * 100).toFixed(0) + "%" : '-',
                        difference: (record.end_day_inventory - record.end_day_inventory_confirmation).toFixed(0),
                    }));
                });
                setRecords(formattedRecords);
                setTotalDays(response.data.data.total_days);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data?.message || 'Failed to load daily records',
                });
            }
        );
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerPageChange = (value) => {
        setPerPage(value);
        setPage(1); // Reset to first page when changing per page
    };

    // Define columns for the table
    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: 'group_name',
            key: 'group_name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Start Day Inventory',
            dataIndex: 'start_day_inventory',
            key: 'start_day_inventory',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'End Day Inventory',
            dataIndex: 'end_day_inventory',
            key: 'end_day_inventory',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'End Day Confirmation',
            dataIndex: 'end_day_inventory_confirmation',
            key: 'end_day_inventory_confirmation',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Used Amount',
            dataIndex: 'used_amount',
            key: 'used_amount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Sold Amount',
            dataIndex: 'sold_amount',
            key: 'sold_amount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Wasted Amount',
            dataIndex: 'wasted_amount',
            key: 'wasted_amount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Waste Percentage',
            dataIndex: 'waste_percentage',
            key: 'waste_percentage',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Difference',
            dataIndex: 'difference',
            key: 'difference',
            showOnResponse: true,
            showOnDesktop: true
        },
    ];

    // Helper function to render each day's records
    const renderRecordsForDay = (date, dayRecords) => (
        <div key={date} className="mb-6">
            <h3 className="text-lg font-bold mb-2">Date: {date}</h3>
            <Table
                antTableProps={{dataSource: dayRecords, columns: columns, pagination: false}}
                columns={columns}
                dataSource={dayRecords}
                rowKey="id"
                pagination={false} // No pagination here, we paginate by days
                mobileBreakPoint={1200}
            />
        </div>
    );

    return (
        <ContainerWrapper>
            <DefaultContainer>
                <div className="mb-4 flex justify-between items-center">
                    <Select defaultValue={3} onChange={handlePerPageChange} className="mr-2">
                        <Select.Option value={1}>1 day per page</Select.Option>
                        <Select.Option value={3}>3 days per page</Select.Option>
                        <Select.Option value={5}>5 days per page</Select.Option>
                    </Select>
                </div>
                <SkeletonWrapper skeleton={MenuSkeleton} loading={loading}>
                    {Object.keys(records).length > 0 && Object.keys(records).map((date) => renderRecordsForDay(date, records[date]))}
                    <div className="mt-4 ml-4">
                        <Pagination
                            current={page}
                            pageSize={perPage}
                            total={totalDays}
                            onChange={handlePageChange}
                            showSizeChanger
                            showTotal={total => `Total ${total} items`}
                            onShowSizeChange={handlePerPageChange}
                        />
                    </div>
                </SkeletonWrapper>
            </DefaultContainer>
        </ContainerWrapper>
    );
};

export default BranchDailyRecords;

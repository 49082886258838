import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Button, Popconfirm, Input, Tag} from 'antd';
import {DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, LockOutlined} from '@ant-design/icons';

const AbilityItem = ({ability, index, groupId, onDelete, onEdit}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newLabel, setNewLabel] = useState(ability.label);
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setLoading(true);
        onEdit(ability.id, newLabel, groupId, () => {
            setLoading(false);
            setIsEditing(false);
        });
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewLabel(ability.label);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <Draggable draggableId={ability.id.toString()} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`ability-item px-2 mb-2 bg-gray-100 rounded flex justify-between items-center ${snapshot.isDragging ? 'opacity-50' : ''}`}
                >
                    <div className="flex items-center">
                        {isEditing ? (
                            <>
                                <Input
                                    value={newLabel}
                                    onChange={(e) => setNewLabel(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    size="small"
                                    style={{width: '400px', marginRight: '10px'}}
                                    disabled={loading}
                                />

                                <Button
                                    type="primary"
                                    icon={<CheckOutlined/>}
                                    size="small"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{marginRight: '5px'}}
                                />

                                <Button
                                    type="default"
                                    icon={<CloseOutlined/>}
                                    size="small"
                                    onClick={handleCancel}
                                    disabled={loading}
                                />

                            </>
                        ) : (
                            <span>
                                 <strong className={"pl-1"}>{ability.label}</strong> <span className={"text-xs"}>({ability.name})</span>
                            </span>
                        )}
                    </div>
                    {!isEditing && (
                        <div>
                            {ability.roles && ability.roles.length > 0 ?
                                <div className={"text-xs bg-gray-200 my-1 flex flex-row items-center px-2 py-1"}>
                                    <LockOutlined className={"mr-1"}/> LOCKED
                                </div>
                            :
                            <>
                                <Popconfirm
                                    title="Are you sure to delete this ability?"
                                    onConfirm={() => onDelete(ability.id, groupId)}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="rightTop"
                                >
                                    <Button type="link" icon={<DeleteOutlined/>} danger/>
                                </Popconfirm>
                                <Button
                                    type="link"
                                    icon={<EditOutlined/>}
                                    onClick={handleEdit}
                                />
                            </>
                            }
                        </div>
                    )}
                </div>
            )}
        </Draggable>
    );
};

export default AbilityItem;

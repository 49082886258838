import React, {useEffect, useState} from 'react';
import {Badge, Button, Popconfirm, Tag, Input} from "antd";
import BaseReview from "../BaseReviews/BaseReview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComputer, faEdit, faPlay, faRobot, faSave, faTrash, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import GooglePlaces from "../../../../../http/GooglePlaces";

const OurReview = ({review, setOurReviews, reload}) =>
{
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [playOpen, setPlayOpen] = useState(false);
    const [playing, setPlaying] = useState(false);

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [autocompleting, setAutocompleting] = useState(false);

    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [editableText, setEditableText] = useState(review.review_text);

    useEffect(() => {
        setEditableText(review.review_text);
    }, [review]);

    const getColorFromStatus = (status) => {
        if(status == "not processed") return "black";
        if(status == "processing") return "blue";
        if(status == "processed") return "green";
        if(status == "failed") return "red";
        if(status == "shadowban") return "purple";
        return "green";
    }

    const onDeleteCancel = () => {
        if(!deleting)
            setDeleteOpen(false);
    }

    const onDeleteOpen = () => {
        setDeleteOpen(true);
    }

    const onDelete = () => {
        setDeleting(true);
        GooglePlaces.DestroyOurReview(review.id,(response) => {
            setDeleting(false);
            setDeleteOpen(false);
            setOurReviews(response.data.data.our_reviews);
        }, (err) => {
            setDeleting(false);
            setDeleteOpen(false);
            reload();
            console.error(err);
        });

    }

    const onPlayCancel = () => {
        if(!deleting)
            setPlayOpen(false);
    }

    const onPlayOpen = () => {
        setPlayOpen(true);
    }

    const onPlay = () => {
        setPlaying(true);
        GooglePlaces.PlayOurReview(review.id,(response) => {
            setPlaying(false);
            setPlayOpen(false);
            setOurReviews(response.data.data.our_reviews);
        }, (err) => {
            setPlaying(false);
            setPlayOpen(false);
            reload();
            console.error(err);
        });
    }

    const onUpdate = () => {
        setUpdating(true);

        GooglePlaces.UpdateOurReview(review.id, editableText, (response) => {
            setUpdating(false);
            setEditing(false);
            setOurReviews(response.data.data.our_reviews);
        }, (err) => {
            setUpdating(false);
            setEditing(false);
            reload();
        });

    }

    const onAutocompleteCancel = () => {
        if(!deleting)
            setAutocompleteOpen(false);
    }

    const onAutocompleteOpen = () => {
        setAutocompleteOpen(true);
    }

    const onAutocomplete = () => {
        setAutocompleting(true);
        GooglePlaces.AutocompleteOurReview(review.id,(response) => {
            setAutocompleting(false);
            setAutocompleteOpen(false);
            setOurReviews(response.data.data.our_reviews);
        }, (err) => {
            setAutocompleting(false);
            setAutocompleteOpen(false);
            reload();
            console.error(err);
        });
    }

    return (
        <div className={`border border-gray-400 px-1 py-1 mb-1 rounded-md  shadow-md flex flex-col ${review.base_review? (review.status=='processed'?'bg-green-200':'bg-red-200'):'bg-gray-50'}`}>
            <div className={"flex flex-row items-start"}>
                <div className={`flex-1 flex flex-row pr-2 ${editing?"items-start":"items-center"}`}>
                    <div className={"uppercase font-bold text-sm flex flex-row items-center"}>
                        <div className={`mr-1 rounded-full ${review.review_rating >= 4?'bg-green-800':''} ${review.review_rating == 3?'bg-yellow-400':''} ${review.review_rating <= 2?'bg-red-700':''} flex items-center justify-center w-5 h-5 font-normal text-sm text-white`}>{review.review_rating}</div>
                    </div>

                    <div className={"flex flex-row items-center"}>
                        <Tag className={"text-xs pr-2 font-bold "} style={{textAlign: "center"}}>
                            {review.should_post_at}
                        </Tag>

                        {review.gmail_id ?
                            <Tag color={getColorFromStatus(review.status)} className={"text-xs pr-2 font-bold "} style={{width: "100px", textAlign: "center"}}>
                                {review.gmail_id}
                            </Tag>
                        :null}

                        <Tag color={getColorFromStatus(review.status)} className={"text-xs pr-2 font-bold "} style={{width: "100px", textAlign: "center"}}>
                            {review.status}
                        </Tag>
                    </div>

                    {(review.status == "shadowban" || review.status == "failed") &&
                        <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 text-base text-yellow-500" aria-hidden="true"/>
                    }

                    {!review.base_review &&
                        <>
                            {editing ?
                                <div className={"w-full"}>
                                    <Input.TextArea
                                        className={"w-full"}
                                        style={{width: "100%"}}
                                        value={editableText}
                                        onChange={e => setEditableText(e.target.value)}
                                    />
                                    <Button type="primary" className={"mt-1"} size="small" loading={updating} icon={<FontAwesomeIcon icon={faSave}/>} onClick={onUpdate}>
                                        Save
                                    </Button>
                                </div>
                            :
                                <div className={"text-xs"}>
                                    {review.review_text?.length > 0 ?
                                        <>
                                            {review.review_text}
                                        </>
                                        :
                                        <div className={"text-gray-200 text-xs"}>NO TEXT PROVIDED</div>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
                {(!review.base_review && review.status == 'not processed') &&
                    <div className={"pr-1"}>


                        <Popconfirm
                            title="Publish this review?"
                            description="Are you sure publish this review right now?"
                            open={playOpen}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onPlay}
                            okButtonProps={{
                                loading: playing,
                            }}
                            onCancel={onPlayCancel}
                        >
                            <FontAwesomeIcon icon={faPlay} className="text-sm cursor-pointer ml-3" onClick={onPlayOpen}/>
                        </Popconfirm>

                        <Popconfirm
                            title="Delete this review?"
                            description="Are you sure to delete this review?"
                            open={deleteOpen}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onDelete}
                            okButtonProps={{
                                loading: deleting,
                            }}
                            onCancel={onDeleteCancel}
                        >
                            <FontAwesomeIcon icon={faTrash} className="text-sm cursor-pointer ml-3" onClick={onDeleteOpen}/>
                        </Popconfirm>

                        <Popconfirm
                            title="AI-Generate the text of this review?"
                            description="Are you sure update this review to AI-Generated?"
                            open={autocompleteOpen}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onAutocomplete}
                            okButtonProps={{
                                loading: autocompleting,
                            }}
                            onCancel={onAutocompleteCancel}
                        >
                            <FontAwesomeIcon icon={faComputer} className="text-sm cursor-pointer ml-3" onClick={onAutocompleteOpen}/>
                        </Popconfirm>


                        <FontAwesomeIcon icon={faEdit} className="text-sm cursor-pointer ml-3" onClick={() => setEditing(true)}/>
                    </div>
                }
            </div>
            {review.base_review &&
                <div className={"mt-1"}>
                    <BaseReview review={review.base_review} />
                </div>
            }

        </div>
    );
};

export default OurReview;
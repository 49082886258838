import React, {useEffect, useState} from 'react';
import {HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import Can from "../../../layouts/Can";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Menu} from 'antd';
import {
    RiUser3Line,
    RiUserHeartLine,
    RiUserAddLine,
    RiTimeLine,
    RiUserLine,
    RiSettings3Line,
    RiBarChartLine,
    RiOrganizationChart,
    RiFolderOpenLine,
    RiCheckboxLine,
    RiTimelineView,
    RiDashboardLine,
    RiBuilding3Line,
    RiShoppingBag3Line,
    RiCheckboxCircleLine,
    RiVipCrownLine,
    RiBookOpenLine,
    RiChat3Line,
    RiBuilding2Line,
    RiFolderLine,
    RiCheckboxBlankCircleLine,
    RiVipLine,
    RiBarChart2Line,
    RiLayoutGridLine,
    RiSettings2Line,
    RiPieChartLine,
    RiMapPinLine,
    RiEdit2Line,
    RiSurveyLine,
    RiFileTextLine,
    RiFileAddLine,
    RiFileListLine,
    RiTruckLine,
    RiCalendarLine,
    RiShoppingCartLine,
    RiShoppingBasketLine,
    RiBillLine,
    RiPercentLine,
    RiMapLine,
    RiTeamLine,
    RiCouponLine, RiSuitcaseLine, RiFileSearchLine, RiCheckLine, RiTerminalLine, RiBankCardLine, RiBox1Fill, RiBox1Line, RiStackLine, RiBox3Line, RiBox2Line, RiCalendar2Line, RiCalendarCloseLine, RiNotification2Line, RiStickyNote2Line, RiMailLine
} from '@remixicon/react';

import {
} from '@remixicon/react';

import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence, motion} from "framer-motion";
import usePermission from "../../../layouts/usePermission";


const DashBoardMenu = ({ collapsed, setCollapsed }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState([]);
    const [desktopMode, setDesktopMode] = useState(window.innerWidth >= 768); // 768px is the md breakpoint in Tailwind
    const dashboardMenuVisible = useSelector(state => state.settings.dashboard_menu_visible);

    // Update desktopMode state on window resize
    useEffect(() => {
        const handleResize = () => setDesktopMode(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (collapsed) return;
        const paths = location.pathname.split('/').filter(x => x);
        let openPaths = [];
        paths.reduce((prev, curr) => {
            const path = `${prev}/${curr}`;
            openPaths.push(path);
            return path;
        }, '');
        setOpenKeys(openPaths);
    }, [location]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    // Permissions
    const canManageBloggers = usePermission(["manage_bloggers"]);
    const canManageKPI = usePermission(["manage_kpi"]);
    const isHQ = usePermission(null, ["hq"]);
    const isIraniManager = usePermission(null, ["irani_manager"]);
    const isReviewManager = usePermission(null, ["review_manager"]);
    const isBranchManager = usePermission(null, ["branch_manager"]);
    const isSupervisor = usePermission(null, ["branch_supervisor"]);
    const isDepartmentManager = usePermission(null, ["department_manager"]);
    const isBranchEmployeeManager = usePermission(null, ["branch_employee_manager"]);
    const isDiscountManager = usePermission(null, ["discount_manager"]);
    const isDiscountAnalyst = usePermission(null, ["discount_analyst"]);
    const isSurveyManager = usePermission(null, ["survey_manager"]);
    const isInventoryManager = usePermission(null, ["inventory_manager"]);
    const isForecastManager = usePermission(null, ["forecast_manager"]);
    const isVendor = usePermission(null, ["vendor"]);
    const CanSeeInventoryMenu = usePermission(null, ["inventory_team_lead", "inventory_assistant_general_manager", "inventory_general_manager"]);
    const CanSeeForecastMenu = usePermission(null, ["inventory_general_manager"]);

    const inventory_manage_items = usePermission(["inventory_manage_items"], null);
    const inventory_manage_containers = usePermission(["inventory_manage_containers"], null);
    const inventory_manage_vendors = usePermission(["inventory_manage_vendors"], null);
    const inventory_manage_vendor_email_templates = usePermission(["inventory_manage_vendor_email_templates"], null);
    const inventory_manage_branch_items = usePermission(["inventory_manage_branch_items"], null);
    const inventory_manage_branch_containers = usePermission(["inventory_manage_branch_containers"], null);
    const inventory_view_end_of_day_start_of_day = usePermission(["inventory_view_end_of_day_start_of_day"], null);
    const inventory_view_end_of_day_report = usePermission(["inventory_view_end_of_day_report"], null);
    const inventory_place_orders = usePermission(["inventory_place_orders"], null);
    const inventory_view_orders_status_history = usePermission(["inventory_view_orders_status_history"], null);
    const inventory_manage_forecasts = usePermission(["inventory_manage_forecasts"], null);
    const inventory_modify_end_of_day_start_of_day = usePermission(["inventory_modify_end_of_day_start_of_day"], null);
    const inventory_modify_end_of_day_report = usePermission(["inventory_modify_end_of_day_report"], null);


    //console.log("dashboardMenuVisible", dashboardMenuVisible);

    let selectedKeys = [location.pathname];

    if (
        location.pathname.endsWith('/manage') ||
        location.pathname.endsWith('/branch') ||
        location.pathname.endsWith('/render') ||
        location.pathname.endsWith('/edit') ||
        location.pathname.endsWith('/submissions')
    ) {
        let basePath = location.pathname.replace(/\/(manage|branch|render|edit|submissions)$/, '');
        selectedKeys = [location.pathname, basePath];

        // Check if the path contains one or two IDs before the suffix
        const idMatch = basePath.match(/\/(\d+)(\/\d+)?$/);
        if (idMatch) {
            const pathWithoutIds = basePath.replace(/\/(\d+)(\/\d+)?$/, '');
            selectedKeys.push(pathWithoutIds);
        }
    }

    //console.log("selectedKeys", selectedKeys);

    return (
        <AnimatePresence>
            {(dashboardMenuVisible || desktopMode) &&
                <motion.div
                    initial={{ x: '-100%' }}  // Start off-screen to the left
                    animate={{ x: 0 }}        // Slide in to the final position
                    exit={{ x: '-100%' }}      // Slide out to the left
                    transition={{ type: "tween", duration: .2 }} // Smooth tweening with duration
                    className={`sidebar-container brand-h-screen overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 relative pt-2 ${desktopMode ? 'md:block' : 'md:hidden'}`}
                    style={{
                        width: 280,
                        position: desktopMode ? 'relative' : 'fixed',
                        zIndex: 1000,
                        height: '100%',
                        left: 0,
                        top: desktopMode ? 0 : 70,
                        marginTop: desktopMode? 6:0,
                        backgroundColor: '#F4F5FA',
                        borderRight: desktopMode?'none':'2px solid gray',
                        scrollbarWidth: 5
                    }}
                >

                    <Menu
                        mode="inline"
                        inlineCollapsed={false}
                        style={{ width: 280 }}
                        onOpenChange={onOpenChange}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        theme="light"
                    >

                        <Menu.Item key="/dashboard" icon={<RiDashboardLine />}>
                            <Link to="/dashboard">Dashboard</Link>
                        </Menu.Item>

                        {canManageBloggers && (
                            <Menu.SubMenu
                                key="/dashboard/bloggers"
                                icon={<RiUser3Line />}
                                title="Bloggers"                            >
                                <Menu.Item key="/dashboard/bloggers/mark" icon={<RiUserHeartLine />}>
                                    <Link to="/dashboard/bloggers/mark">Mark</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/bloggers/invite" icon={<RiUserAddLine />}>
                                    <Link to="/dashboard/bloggers/invite">Invite</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/bloggers/invitees" icon={<RiTimeLine />}>
                                    <Link to="/dashboard/bloggers/invitees">Invitees</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/bloggers/attends" icon={<RiUserLine />}>
                                    <Link to="/dashboard/bloggers/attends">Attends</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/bloggers/settings" icon={<RiSettings3Line />}>
                                    <Link to="/dashboard/bloggers/settings">Settings</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {canManageKPI && (
                            <Menu.Item key="/dashboard/KPI" icon={<RiBarChartLine />}>
                                <Link to="/dashboard/KPI">KPI Reports</Link>
                            </Menu.Item>
                        )}

                        {isHQ && (
                            <Menu.SubMenu
                                key="/dashboard/divisions"
                                icon={<RiOrganizationChart />}
                                title="Divisions"
                            >
                                <Menu.Item key="/dashboard/divisions/open_subjects" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/divisions/open_subjects">Open Projects</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/divisions/my_tasks" icon={<RiCheckboxLine />}>
                                    <Link to="/dashboard/divisions/my_tasks">My Tasks</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/divisions/timeline" icon={<RiTimelineView />}>
                                    <Link to="/dashboard/divisions/timeline">Timeline</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/divisions/index" icon={<RiDashboardLine />}>
                                    <Link to="/dashboard/divisions/index">All Divisions</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/divisions/tree" icon={<RiOrganizationChart />}>
                                    <Link to="/dashboard/divisions/tree">Company Tree</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/irani"
                                icon={<RiBuilding3Line />}
                                title="Irani"
                            >
                                <Menu.Item key="/dashboard/irani/validate" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/irani/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/irani/khaz_brands">Khaz Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/standard_brands" icon={<RiCheckboxCircleLine />}>
                                    <Link to="/dashboard/irani/standard_brands">Standard Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/fancy_brands" icon={<RiVipCrownLine />}>
                                    <Link to="/dashboard/irani/fancy_brands">Fancy Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/reports" icon={<RiBarChartLine />}>
                                    <Link to="/dashboard/irani/reports">Nationalities</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/context/reports" icon={<RiBookOpenLine />}>
                                    <Link to="/dashboard/irani/context/reports">Contexts</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/irani/review/reports" icon={<RiChat3Line />}>
                                    <Link to="/dashboard/irani/review/reports">Reviews</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/usa"
                                icon={<RiBuilding3Line />}
                                title="USA"
                            >
                                <Menu.Item key="/dashboard/usa/validate" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/usa/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/usa/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/usa/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/usa/standard_brands" icon={<RiCheckboxCircleLine />}>
                                    <Link to="/dashboard/usa/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/usa/fancy_brands" icon={<RiVipCrownLine />}>
                                    <Link to="/dashboard/usa/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/dubai"
                                icon={<RiBuilding3Line />}
                                title="Dubai"
                            >
                                <Menu.Item key="/dashboard/dubai/validate" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/dubai/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/dubai/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/dubai/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/dubai/standard_brands" icon={<RiCheckboxCircleLine />}>
                                    <Link to="/dashboard/dubai/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/dubai/fancy_brands" icon={<RiVipCrownLine />}>
                                    <Link to="/dashboard/dubai/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/london"
                                icon={<RiBuilding3Line />}
                                title="London"
                            >
                                <Menu.Item key="/dashboard/london/validate" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/london/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/london/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/london/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/london/standard_brands" icon={<RiCheckboxCircleLine />}>
                                    <Link to="/dashboard/london/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/london/fancy_brands" icon={<RiVipCrownLine />}>
                                    <Link to="/dashboard/london/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/paris"
                                icon={<RiBuilding3Line />}
                                title="Paris"
                            >
                                <Menu.Item key="/dashboard/paris/validate" icon={<RiFolderOpenLine />}>
                                    <Link to="/dashboard/paris/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/paris/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/paris/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/paris/standard_brands" icon={<RiCheckboxCircleLine />}>
                                    <Link to="/dashboard/paris/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/paris/fancy_brands" icon={<RiVipCrownLine />}>
                                    <Link to="/dashboard/paris/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}


                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/istanbul"
                                icon={<RiBuilding2Line />}
                                title="Istanbul"
                            >
                                <Menu.Item key="/dashboard/istanbul/validate" icon={<RiFolderLine />}>
                                    <Link to="/dashboard/istanbul/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/istanbul/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/istanbul/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/istanbul/standard_brands" icon={<RiCheckboxBlankCircleLine />}>
                                    <Link to="/dashboard/istanbul/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/istanbul/fancy_brands" icon={<RiVipLine />}>
                                    <Link to="/dashboard/istanbul/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/canada"
                                icon={<RiBuilding2Line />}
                                title="Canada"
                            >
                                <Menu.Item key="/dashboard/canada/validate" icon={<RiFolderLine />}>
                                    <Link to="/dashboard/canada/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/canada/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/canada/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/canada/standard_brands" icon={<RiCheckboxBlankCircleLine />}>
                                    <Link to="/dashboard/canada/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/canada/fancy_brands" icon={<RiVipLine />}>
                                    <Link to="/dashboard/canada/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/tehran"
                                icon={<RiBuilding2Line />}
                                title="Tehran"
                            >
                                <Menu.Item key="/dashboard/tehran/validate" icon={<RiFolderLine />}>
                                    <Link to="/dashboard/tehran/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/tehran/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/tehran/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/tehran/standard_brands" icon={<RiCheckboxBlankCircleLine />}>
                                    <Link to="/dashboard/tehran/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/tehran/fancy_brands" icon={<RiVipLine />}>
                                    <Link to="/dashboard/tehran/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/kingcounty"
                                icon={<RiBuilding2Line />}
                                title="King County"
                            >
                                <Menu.Item key="/dashboard/kingcounty/validate" icon={<RiFolderLine />}>
                                    <Link to="/dashboard/kingcounty/validate">Validate</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/kingcounty/khaz_brands" icon={<RiShoppingBag3Line />}>
                                    <Link to="/dashboard/kingcounty/khaz_brands">Age Residim Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/kingcounty/standard_brands" icon={<RiCheckboxBlankCircleLine />}>
                                    <Link to="/dashboard/kingcounty/standard_brands">Mohem Brands</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/kingcounty/fancy_brands" icon={<RiVipLine />}>
                                    <Link to="/dashboard/kingcounty/fancy_brands">Vajeb Brands</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isIraniManager && (
                            <Menu.SubMenu
                                key="/dashboard/bigbang"
                                icon={<RiMapLine />}
                                title="Big Bang"
                            >
                                <Menu.Item key="/dashboard/bigbang/reports" icon={<RiPieChartLine />}>
                                    <Link to="/dashboard/bigbang/reports">Reports</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isReviewManager && (
                            <Menu.SubMenu
                                key="/dashboard/reviews"
                                icon={<RiEdit2Line />}
                                title="Reviews"
                            >
                                <Menu.Item key="/dashboard/reviews/places" icon={<RiMapPinLine />}>
                                    <Link to="/dashboard/reviews/places">Places</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/reviews/base_reviews" icon={<RiEdit2Line />}>
                                    <Link to="/dashboard/reviews/base_reviews">Google Reviews</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/reviews/our_reviews" icon={<RiEdit2Line />}>
                                    <Link to="/dashboard/reviews/our_reviews">Our Reviews</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {isSurveyManager && (
                            <Menu.SubMenu
                                key="/dashboard/survey"
                                icon={<RiSurveyLine />}
                                title="Survey"
                            >
                                <Menu.Item key="/dashboard/survey/form" icon={<RiFileTextLine />}>
                                    <Link to="/dashboard/survey/form/branch">Form</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/survey/reports" icon={<RiPieChartLine />}>
                                    <Link to="/dashboard/survey/reports/branch">Reports</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {(isBranchManager || isDepartmentManager) && (
                            <Menu.SubMenu
                                key="/dashboard/departments"
                                icon={<RiBuilding2Line />}
                                title="Departments"
                            >
                                <Menu.Item key="/dashboard/departments" icon={<RiSettings2Line />}>
                                    <Link to="/dashboard/departments/manage">Manage</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        <div className="flex items-center my-4 hidden">
                            <div className="w-[20px] border-t border-gray-300"></div>
                            <span className="mx-4 text-gray-400 text-sm whitespace-nowrap text-xs">Forms & Settings</span>
                            <div className="flex-grow border-t border-gray-300"></div>
                        </div>

                        {isBranchManager && (
                            <Menu.SubMenu
                                key="/dashboard/forms"
                                icon={<RiFileListLine />}
                                title="Forms"
                            >
                                <Menu.Item key="/dashboard/forms/create" icon={<RiFileAddLine />}>
                                    <Link to="/dashboard/forms/create">Create</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/forms/manage" icon={<RiFileTextLine />}>
                                    <Link to="/dashboard/forms/manage">Manage</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {(isInventoryManager || isVendor || CanSeeInventoryMenu) && (
                            <Menu.SubMenu
                                key="/dashboard/inventory"
                                icon={<RiSuitcaseLine />}
                                title="Inventory"
                            >
                                {(isInventoryManager || inventory_manage_items) &&
                                    <Menu.Item key="/dashboard/inventory" icon={<RiEdit2Line/>}>
                                        <Link to="/dashboard/inventory/manage">Manage</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_manage_containers) &&
                                    <Menu.Item key="/dashboard/inventory/containers" icon={<RiBox1Line/>}>
                                        <Link to="/dashboard/inventory/containers/manage">Containers</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_manage_vendors) &&
                                    <Menu.Item key="/dashboard/inventory/vendors" icon={<RiTruckLine/>}>
                                        <Link to="/dashboard/inventory/vendors/manage">Vendors</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_manage_branch_items) &&
                                    <Menu.Item key="/dashboard/inventory/branch_group" icon={<RiStackLine/>}>
                                        <Link to="/dashboard/inventory/branch_group/manage">Branch Items</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_manage_branch_containers) &&
                                    <Menu.Item key="/dashboard/inventory/branch_inventory_group_containers" icon={<RiBox2Line/>}>
                                        <Link to="/dashboard/inventory/branch_inventory_group_containers/manage">Branch Containers</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day) &&
                                    <Menu.Item key="/dashboard/inventory/end-of-day" icon={<RiCalendarLine/>}>
                                        <Link to="/dashboard/inventory/end-of-day/branch">End of Day</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_place_orders) &&
                                    <Menu.Item key="/dashboard/inventory/order" icon={<RiShoppingCartLine/>}>
                                        <Link to="/dashboard/inventory/order/branch">Order</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day) &&
                                    <Menu.Item key="/dashboard/inventory/start-of-day" icon={<RiCalendar2Line/>}>
                                        <Link to="/dashboard/inventory/start-of-day/branch">Start of Day</Link>
                                    </Menu.Item>
                                }
                                {(isInventoryManager || inventory_view_end_of_day_report || inventory_modify_end_of_day_report) &&
                                    <Menu.Item key="/dashboard/inventory/reports" icon={<RiBarChart2Line/>}>
                                        <Link to="/dashboard/inventory/reports/branch">Reports</Link>
                                    </Menu.Item>
                                }

                                {(isInventoryManager || inventory_manage_vendor_email_templates) &&
                                    <Menu.Item key="/dashboard/inventory/email-templates" icon={<RiMailLine/>}>
                                        <Link to="/dashboard/inventory/email-templates">Email Templates</Link>
                                    </Menu.Item>
                                }

                                {(isInventoryManager || inventory_view_orders_status_history) &&
                                    <Menu.Item key="/dashboard/inventory/branch-orders" icon={<RiShoppingBasketLine/>}>
                                        <Link to="/dashboard/inventory/branch-orders/branch">Branch Orders</Link>
                                    </Menu.Item>
                                }
                                {isVendor &&
                                    <Menu.Item key="/dashboard/inventory/vendor-orders" icon={<RiBillLine/>}>
                                        <Link to="/dashboard/inventory/vendor-orders">Production</Link>
                                    </Menu.Item>
                                }
                            </Menu.SubMenu>
                        )}

                        {(isBranchManager || isForecastManager || inventory_manage_forecasts) && (
                            <Menu.SubMenu
                                key="/dashboard/forecast"
                                icon={<RiPieChartLine />}
                                title="Forecast"
                            >
                                {(isBranchManager || isForecastManager) &&
                                    <Menu.Item key="/dashboard/forecast/settings" icon={<RiSettings2Line/>}>
                                        <Link to="/dashboard/forecast/settings">Settings</Link>
                                    </Menu.Item>
                                }
                                {(isBranchManager || isForecastManager) &&
                                    <Menu.Item key="/dashboard/forecast/forecasts" icon={<RiBarChart2Line/>}>
                                        <Link to="/dashboard/forecast/forecasts/branch">Forecasts</Link>
                                    </Menu.Item>
                                }
                                {(isBranchManager || isForecastManager) &&
                                    <Menu.Item key="/dashboard/forecast/coefficients" icon={<RiPercentLine/>}>
                                        <Link to="/dashboard/forecast/coefficients/branch">Coefficients</Link>
                                    </Menu.Item>
                                }
                                {(isBranchManager || isForecastManager) &&
                                    <Menu.Item key="/dashboard/forecast/models" icon={<RiMapLine/>}>
                                        <Link to="/dashboard/forecast/models">Models</Link>
                                    </Menu.Item>
                                }
                            </Menu.SubMenu>
                        )}

                        {(isBranchManager || isBranchEmployeeManager) && (
                            <Menu.SubMenu
                                key="/dashboard/employees"
                                icon={<RiTeamLine />}
                                title="Employees"
                            >
                                <Menu.Item key="/dashboard/employees" icon={<RiUser3Line />}>
                                    <Link to="/dashboard/employees/manage">Manage</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/employees/roles" icon={<RiSettings2Line />}>
                                    <Link to="/dashboard/employees/roles">Roles</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/employees/abilities" icon={<RiTeamLine />}>
                                    <Link to="/dashboard/employees/abilities">Abilities</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {(isBranchManager || isBranchEmployeeManager) && (
                            <Menu.SubMenu
                                key="/dashboard/terminals"
                                icon={<RiTerminalLine />}
                                title="Terminals"
                            >
                                <Menu.Item key="/dashboard/terminals/devices" icon={<RiBankCardLine />}>
                                    <Link to="/dashboard/terminals/devices">Devices</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        <Menu.SubMenu
                            key="/dashboard/coupons"
                            icon={<RiCouponLine />}
                            title="Coupons"
                        >
                            {(isBranchManager || isDiscountManager) && (
                                <>
                                    <Menu.Item key="/dashboard/coupons/discount_presets" icon={<RiBillLine />}>
                                        <Link to="/dashboard/coupons/discount_presets">Discount Presets</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/dashboard/coupons/generate_presets" icon={<RiFileAddLine />}>
                                        <Link to="/dashboard/coupons/generate_presets">Generate Presets</Link>
                                    </Menu.Item>
                                </>
                            )}
                            <Menu.Item key="/dashboard/coupons/generate_index" icon={<RiSettings2Line />}>
                                <Link to="/dashboard/coupons/generate_index">Generate</Link>
                            </Menu.Item>

                            {(isBranchManager || isSupervisor) && (
                                <Menu.Item key="/dashboard/coupons/approve" icon={<RiCheckLine />}>
                                    <Link to="/dashboard/coupons/approve">Approve</Link>
                                </Menu.Item>
                            )}

                            {(isBranchManager || isDiscountManager || isDiscountAnalyst) && (
                                <Menu.Item key="/dashboard/coupons/reports" icon={<RiPieChartLine />}>
                                    <Link to="/dashboard/coupons/reports/branch">Reports</Link>
                                </Menu.Item>
                            )}
                        </Menu.SubMenu>

                        {isBranchManager && false && (
                            <Menu.SubMenu
                                key="/dashboard/training"
                                icon={<RiSurveyLine />}
                                title="Training"
                            >
                                <Menu.Item key="/dashboard/training/manage" icon={<RiUser3Line />}>
                                    <Link to="/dashboard/training/manage">Manage</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/training/checklists" icon={<RiCheckLine />}>
                                    <Link to="/dashboard/training/checklists">Checklists</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                    </Menu>
                    <div className={"h-16"}>

                    </div>
                </motion.div>
            }
        </AnimatePresence>
    );
};


export default DashBoardMenu;